import PropTypes from 'prop-types';
import React from 'react';

const sizes = {
  '6xl': 'text-5xl',
  '5xl': 'text-4xl',
  '4xl': 'text-3xl',
  '3xl': 'text-2xl',
  '2xl': 'text-xl',
  xl: 'text-lg',
  lg: 'text-base',
  md: 'text-sm',
  sm: 'text-xs',
  xs: 'text-xxs',
};

const weights = {
  normal: 'font-normal',
  semibold: 'font-semibold',
  bold: 'font-bold',
};

const Text = React.forwardRef(function Text(
  {children, className, size, weight, as, ...props},
  ref
) {
  const ComponentTag = as;

  const textSize = sizes[size] ?? sizes['3xl'];
  const weightClass = weights[weight] ?? weights.normal;

  return (
    <ComponentTag
      className={`font-source-sans-pro leading-tight ${textSize} ${weightClass} ${className}`}
      data-testid="text"
      ref={ref}
      {...props}
    >
      {children}
    </ComponentTag>
  );
});

export default Text;

Text.defaultProps = {
  className: '',
  size: '3xl',
  weight: 'normal',
  as: 'p',
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['6xl', '5xl', '4xl', '3xl', '2xl', 'xl', 'lg', 'md', 'sm', 'xs']),
  weight: PropTypes.oneOf(['normal', 'semibold', 'bold']),
  as: PropTypes.string,
};
