import PropTypes from 'prop-types';
import {forwardRef} from 'react';

const baseStyle =
  'flex items-end justify-center capitalize font-source-sans-pro whitespace-nowrap transition-all';

const CAPSULE = {
  style: 'gap-1 rounded-full font-semibold',
  sizes: {
    sm: 'py-3 px-6 text-sm',
    md: 'py-4 px-7 text-base',
    lg: 'py-5 px-9 text-lg',
    xl: 'py-6 px-12 text-xl',
    custom: '',
  },
};
const SQUARE = {
  style: 'rounded-md font-semibold',
  sizes: {
    sm: 'py-[7px] px-4 text-sm gap-x-[5px]',
    md: 'py-2 px-5 text-base gap-x-1.5',
    lg: 'py-3 px-6 text-lg gap-x-[7px]',
    xl: 'py-4 px-8 text-xl gap-x-2',
    custom: '',
  },
};

const TEXT = {
  style: 'font-normal',
  sizes: {
    sm: 'text-sm',
    md: 'text-base',
    lg: 'text-lg',
  },
};

const VARIANT = {
  primaryRegular: {
    sizes: SQUARE.sizes,
    style: `${SQUARE.style} bg-primary-500 text-white hover:bg-primary-800 focus:bg-primary-600 focus:outline focus:outline-offset-2 focus:outline-2 focus:outline-primary-500 active:bg-primary-900 disabled:text-gray-300 disabled:bg-gray-100`,
  },
  primaryOutlined: {
    sizes: SQUARE.sizes,
    style: `${SQUARE.style} border border-primary-500 font-semibold text-primary-500 hover:border-primary-800 hover:text-primary-800`,
  },
  secondaryRegular: {
    sizes: SQUARE.sizes,
    style: `${SQUARE.style} bg-secondary-500 text-white hover:bg-secondary-700 disabled:text-gray-300 disabled:bg-gray-100 focus:outline focus:outline-offset-2 focus:outline-2 focus:outline-secondary-500 focus:bg-secondary-600 active:bg-secondary-900`,
  },
  secondaryOutlined: {
    sizes: {
      sm: 'py-[7px] px-4 text-sm gap-x-[5px]',
      md: 'py-2 px-5 text-base gap-x-1.5',
      lg: 'py-3 px-6 text-lg gap-x-[7px]',
      xl: 'py-4 px-8 text-xl gap-x-2',
      custom: '',
    },
    style:
      'flex items-center justify-center rounded-md capitalize border-secondary-500 border-2 font-semibold font-source-sans-pro whitespace-nowrap  bg-white text-secondary-500 focus:text-secondary-600 focus:border-secondary-600  focus:outline focus:outline-offset-2 focus:outline-2 focus:outline-secondary-600 hover:text-secondary-700 hover:border-secondary-700 active:text-secondary-900 active:bg-gray-100 active:border-secondary-900 disabled:text-gray-300 disabled:bg-gray-100 disabled:border-gray-300',
  },
  tertiary: {
    sizes: SQUARE.sizes,
    style: `${SQUARE.style} bg-white text-cold-gray-700 border border-cold-gray-400 focus:border focus:border-cold-gray-600 focus:outline focus:outline-offset-2 focus:outline-2 focus:outline-cold-gray-400 hover:border-cold-gray-700 active:text-cold-gray-900 active:bg-cold-gray-100 active:border-cold-gray-900 disabled:text-gray-300 disabled:bg-gray-100 disabled:border-gray-300 hover:border-cold-gray-700`,
  },
  greenPricing: {
    sizes: SQUARE.sizes,
    style: `${SQUARE.style} bg-green-pricing-default text-white outline-none focus:outline focus:outline-offset-2 focus:outline-2 focus:outline-green-pricing-default hover:bg-green-pricing-hover active:bg-green-pricing-pressed`,
  },
  capsule: {
    sizes: CAPSULE.sizes,
    style: `${CAPSULE.style} bg-white text-secondary-500 shadow-3xl hover:text-secondary-700 disabled:text-gray-300 disabled:bg-gray-100 disabled:shadow-none focus:shadow-4xl focus:border focus:border-secondary-500 focus:text-secondary-500 active:text-secondary-700 active:bg-gray-100 active:shadow-xs active:border-0`,
  },
  textGray: {
    sizes: TEXT.sizes,
    style: `${TEXT.style} gap-1 text-neutral-gray-800 hover:text-gray-600 disabled:text-gray-300 disabled:bg-gray-100 disabled:shadow-none focus:text-neutral-gray-800 active:text-gray-400 focus:outline-transparent focus:rounded`,
  },
  textWhite: {
    sizes: TEXT.sizes,
    style: `${TEXT.style} border-none text-white h-full hover:text-gray-400`,
  },
  landingPage: {
    sizes: SQUARE.sizes,
    style: `${SQUARE.style} bg-white text-cold-gray-700 focus:outline focus:outline-offset-2 focus:outline-2 focus:outline-cold-gray-600 shadow-2xl hover:bg-cold-gray-100 active:bg-secondary-900 active:text-white disabled:text-gray-400 disabled:bg-gray-100`,
  },
};

/**
- Can't be created without at least one of: children, leftIcon, rightIcon
- Button styles are accessed by selecting a variant
* */
/* eslint-disable react/button-has-type */
const BaseButton = forwardRef(
  ({size, variant, children, leftIcon, rightIcon, className, type, ...props}, ref) => {
    const style = `${baseStyle} ${VARIANT[variant].style} ${VARIANT[variant].sizes[size]} ${className}`;

    return (
      <button ref={ref} type={type} className={style} {...props}>
        {leftIcon}
        {children}
        {rightIcon}
      </button>
    );
  }
);
BaseButton.displayName = 'BaseButton';

BaseButton.propTypes = {
  /** The values the shorthand properties represent vary by variant */
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'custom']),
  /** These are defined in the UI Kit */
  variant: PropTypes.oneOf([
    'primaryRegular',
    'primaryOutlined',
    'secondaryRegular',
    'secondaryOutlined',
    'capsule',
    'tertiary',
    'greenPricing',
    'textGray',
    'landingPage',
    'textWhite',
  ]),
  /** Any css that should be passed to the button element */
  className: PropTypes.string,
  /**  The default behavior of the button */
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  /**
   - Can't be empty if leftIcon and rightIcon are also empty
   - Must be a node
   */
  children: props => {
    if (!props.children && !props.leftIcon && !props.rightIcon) {
      return new Error(`Must include one of: 'children','leftIcon','rightIcon'`);
    }
    if (props.children) {
      PropTypes.checkPropTypes(
        {children: PropTypes.node},
        {children: props.children},
        'prop',
        'BaseButton'
      );
    }
    return null;
  },
  /**
   - Can't be empty if children and rightIcon are also empty
   - Must be an instance of Icon
   */
  leftIcon: props => {
    if (!props.children && !props.leftIcon && !props.rightIcon) {
      return new Error(`Must include one of: 'children','leftIcon','rightIcon'`);
    }
    if (props.leftIcon) {
      PropTypes.checkPropTypes(
        {leftIcon: PropTypes.element},
        {leftIcon: props.leftIcon},
        'prop',
        'BaseButton'
      );
    }
    return null;
  },
  /**
   - Can't be empty if children and leftIcon are also empty
   - Must be an instance of Icon
   */
  rightIcon: props => {
    if (!props.children && !props.leftIcon && !props.rightIcon) {
      return new Error(`Must include one of: 'children','leftIcon','rightIcon'`);
    }
    if (props.rightIcon) {
      PropTypes.checkPropTypes(
        {rightIcon: PropTypes.element},
        {rightIcon: props.rightIcon},
        'prop',
        'BaseButton'
      );
    }
    return null;
  },
};

BaseButton.defaultProps = {
  size: 'md',
  variant: 'primaryRegular',
  children: null,
  leftIcon: null,
  rightIcon: null,
  className: '',
  type: 'button',
};

export default BaseButton;
