import {useState} from 'react';
import PropTypes from 'prop-types';
import {IcCloseSm} from '../Atoms/Icons';

/**
 * Creates a Header Strip to display campaign contents
 */
export default function HeaderStripCampaign({
  className,
  classNames,
  useLeftImage,
  useRightImage,
  rightImage,
  leftImage,
  children,
  onClose,
}) {
  const [isActive, setIsActive] = useState(true);
  const toggleActive = () => {
    setIsActive(active => !active);
    if (isActive) {
      onClose();
    }
  };
  /**
   * Default styles for the component elements
   */
  const headerStyle = `flex flex-row relative w-full pr-[50px] sm:pl-[50px] pl-3 py-2.5 items-center justify-center ${className}`;
  const imageStyle = `absolute top-0 object-cover w-1/5 h-full ${classNames.image}`;
  const buttonStyle = `absolute right-0 mr-2.5 flex h-6 w-6 lg:h-7 lg:w-7 items-center justify-center rounded-3xl bg-neutral-700 opacity-90 ${classNames.closeButton}`;

  return (
    <header className={isActive ? headerStyle : 'hidden'}>
      <img
        className={useLeftImage ? `${imageStyle} left-0 object-right` : 'hidden'}
        src={leftImage}
        alt="Left side"
      />
      <img
        className={useRightImage ? `${imageStyle} right-0 object-left` : 'hidden'}
        src={rightImage}
        alt="Right side"
      />
      <div className="z-0 flex h-full w-full items-center justify-center">{children}</div>
      <button type="button" className={buttonStyle} onClick={toggleActive}>
        <IcCloseSm className="h-4 w-4 fill-white lg:h-5 lg:w-5" />
      </button>
    </header>
  );
}

HeaderStripCampaign.propTypes = {
  /**
   *  Optional CSS classes to be applied to the Header Strip Campaign component
   */
  className: PropTypes.string,
  /**
   *  Optional CSS classes to be applied to the images
   */
  classNames: PropTypes.shape({
    image: PropTypes.string,
    closeButton: PropTypes.string,
  }),
  /**
   *  The Left image source
   */
  leftImage: PropTypes.string,
  /**
   *  The Right image source
   */
  rightImage: PropTypes.string,
  /**
   *  Defines if the Left image is going to be used
   */
  useLeftImage: PropTypes.bool,
  /**
   *  Defines if the Right image is going to be used
   */
  useRightImage: PropTypes.bool,
  /**
   *  Child nodes passed into the HeaderStripCampaign component as children, ie: <Text />
   */
  children: PropTypes.node.isRequired,
  /**
   *  Child nodes passed into the HeaderStripCampaign component as children, ie: <Text />
   */
  onClose: PropTypes.func,
};

HeaderStripCampaign.defaultProps = {
  className: '',
  classNames: {
    image: '',
    closeButton: '',
  },
  leftImage: '',
  rightImage: '',
  useLeftImage: false,
  useRightImage: false,
  onClose: e => e,
};

export const PlasmicData = {
  name: 'HeaderStripCampaign',
  props: {
    className: 'string',
    useLeftImage: 'boolean',
    leftImage: 'imageUrl',
    useRightImage: 'boolean',
    rightImage: 'imageUrl',
    children: {
      type: 'slot',
      defaultValue: [
        {
          type: 'text',
          value: 'Header Contents',
        },
      ],
    },
  },
  importPath: './components/UI/Molecules/HeaderStripCampaign',
  isDefaultExport: true,
};
