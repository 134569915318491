import PropTypes from 'prop-types';
import Text from '../Atoms/Typography/Text';
import BaseButton from './BaseButton';
import {IcSystemInfo, IcSuccess, IcWarning} from '../Atoms/Icons';

const STYLES = {
  info: {
    bg: 'bg-info-500',
    textBg: 'bg-info-100',
    text: 'text-info-800',
    icon: <IcSystemInfo className="h-8 w-8" variant="multi" />,
  },
  danger: {
    bg: 'bg-red-500',
    textBg: 'bg-danger-100',
    text: 'text-danger-800',
    icon: <IcWarning className="h-8 w-8" variant="multi" stroke="#90113C" fill="#FEE2D6" />,
  },
  warning: {
    bg: 'bg-warning-500',
    textBg: 'bg-warning-100',
    text: 'text-warning-800',
    icon: <IcWarning className="h-8 w-8" variant="multi" stroke="#936901" fill="#FFF8CD" />,
  },
  success: {
    bg: 'bg-success-500',
    textBg: 'bg-success-100',
    text: 'text-success-800',
    icon: <IcSuccess className="h-8 w-8" variant="multi" />,
  },
};

/**
 * The Headerstrip is used to show very important or global system communication to all users about the product.
 */
export default function Headerstrip({type, message, button, onClick}) {
  return (
    <div className="flex w-full">
      <div className={`flex min-w-[40px] items-center justify-center ${STYLES[type].bg}`}>
        {STYLES[type].icon}
      </div>
      <div
        className={`flex flex-grow flex-col items-start justify-between p-4 lg:flex-row lg:items-center ${STYLES[type].textBg}`}
      >
        <Text size="lg" className={`${STYLES[type].text} lg:text-xl 3xl:text-2xl`}>
          {message}
        </Text>
        {button.length > 1 && (
          <BaseButton onClick={onClick} variant="tertiary" className="mt-2 lg:ml-6 lg:mt-0">
            {button}
          </BaseButton>
        )}
      </div>
    </div>
  );
}

Headerstrip.defaultProps = {
  button: '',
  type: 'info',
  onClick: () => {},
};

Headerstrip.propTypes = {
  /** Text to be displayed */
  message: PropTypes.string.isRequired,
  /** Text for the button, will not display a button if this is empty */
  button: PropTypes.string,
  /** Event handler called when the button is clicked. */
  onClick: PropTypes.func,
  /** The type affects the colors and icon. */
  type: PropTypes.oneOf(['info', 'danger', 'warning', 'success']),
};

export const PlasmicData = {
  name: 'Headerstrip',
  props: {
    message: 'string',
    type: {
      type: 'choice',
      options: ['info', 'danger', 'warning', 'success'],
      defaultValue: 'info',
    },
    button: 'string',
    onClick: {
      type: 'eventHandler',
      argTypes: [{name: 'event', type: 'object'}],
    },
  },
  importPath: './components/UI/Molecules/Headerstrip',
  isDefaultExport: true,
};
