import dynamic from 'next/dynamic';

const IcArrowDown = dynamic(() => import('./IcArrowDown'));
const IcBundles = dynamic(() => import('./IcBundle'));
const IcCheck01 = dynamic(() => import('./IcCheck01'));
const IcChevronExpand = dynamic(() => import('./IcChevronExpand'));
const IcChevronLeft = dynamic(() => import('./IcChevronLeft'));
const IcChevronRight = dynamic(() => import('./IcChevronRight'));
const IcChevronRightCurved = dynamic(() => import('./IcChevronRightCurved'));
const IcChevronCollapse = dynamic(() => import('./IcChevronCollapse'));
const IcCircleClock = dynamic(() => import('./IcCircleClock'));
const IcCloseM = dynamic(() => import('./IcCloseM'));
const IcCloseSlim = dynamic(() => import('./IcCloseSlim'));
const IcCloseSm = dynamic(() => import('./IcCloseSm'));
const IcCloseXl = dynamic(() => import('./IcCloseXl'));
const IcCrown01 = dynamic(() => import('./IcCrown01'));
const IcDislike = dynamic(() => import('./IcDislike'));
const IcDownload = dynamic(() => import('./IcDownload'));
const IcDraggableElements = dynamic(() => import('./IcDraggableElements'));
const IcEdit = dynamic(() => import('./IcEdit'));
const IcExpressVideo = dynamic(() => import('./IcExpressVideo'));
const IcFavoriteOn = dynamic(() => import('./IcFavoriteOn'));
const IcFavoriteOff = dynamic(() => import('./IcFavoriteOff'));
const IcFolder = dynamic(() => import('./IcFolder'));
const IcFontSize = dynamic(() => import('./IcFontSize'));
const IcForYou = dynamic(() => import('./IcForYou'));
const IcHide = dynamic(() => import('./IcHide'));
const IcHome02 = dynamic(() => import('./IcHome02'));
const IcImage = dynamic(() => import('./IcImage'));
const IcImg = dynamic(() => import('./IcImg'));
const IcLike = dynamic(() => import('./IcLike'));
const IcLink = dynamic(() => import('./IcLink'));
const IcLogin = dynamic(() => import('./IcLogin'));
const IcLogout = dynamic(() => import('./IcLogout'));
const IcMenuHamburger = dynamic(() => import('./IcMenuHamburger'));
const IcMyPlaceit = dynamic(() => import('./IcMyPlaceit'));
const IcPen = dynamic(() => import('./IcPen'));
const IcRectangleFlag02 = dynamic(() => import('./IcRectangleFlag02'));
const IcSearch01 = dynamic(() => import('./IcSearch01'));
const IcSettings03 = dynamic(() => import('./IcSettings03'));
const IcShare02 = dynamic(() => import('./IcShare02'));
const IcShow = dynamic(() => import('./IcShow'));
const IcSuccess = dynamic(() => import('./IcSuccess'));
const IcSystemInfo = dynamic(() => import('./IcSystemInfo'));
const IcTransparent = dynamic(() => import('./IcTransparent'));
const IcUpload = dynamic(() => import('./IcUpload'));
const IcUser01 = dynamic(() => import('./IcUser01'));
const IcUser02 = dynamic(() => import('./IcUser02'));
const IcUserLogin = dynamic(() => import('./IcUserLogin'));
const IcVideo = dynamic(() => import('./IcVideo'));
const IcWarning = dynamic(() => import('./IcWarning'));
const IcThreeDots = dynamic(() => import('./IcThreeDot'));
const IcViewAll = dynamic(() => import('./IcViewAll'));

export {
  IcArrowDown,
  IcBundles,
  IcCheck01,
  IcChevronExpand,
  IcChevronLeft,
  IcChevronRight,
  IcChevronRightCurved,
  IcChevronCollapse,
  IcCircleClock,
  IcCloseM,
  IcCloseSlim,
  IcCloseSm,
  IcCloseXl,
  IcCrown01,
  IcDislike,
  IcDownload,
  IcDraggableElements,
  IcEdit,
  IcExpressVideo,
  IcFavoriteOn,
  IcFavoriteOff,
  IcFolder,
  IcFontSize,
  IcForYou,
  IcHide,
  IcHome02,
  IcImage,
  IcImg,
  IcLike,
  IcLink,
  IcLogin,
  IcLogout,
  IcMenuHamburger,
  IcMyPlaceit,
  IcPen,
  IcRectangleFlag02,
  IcSearch01,
  IcSettings03,
  IcShare02,
  IcShow,
  IcSuccess,
  IcSystemInfo,
  IcTransparent,
  IcUpload,
  IcUser01,
  IcUser02,
  IcUserLogin,
  IcVideo,
  IcWarning,
  IcThreeDots,
  IcViewAll,
};
